import React, { useState, useEffect, useMemo, useRef } from 'react';
import useSWR from 'swr'
import {
  Stack,
  HStack,
  Center, 
  Text,
  FormErrorMessage,
  FormLabel,
  FormControl,
  FormHelperText,
  Input,
  Textarea,
  Switch,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  IconButton,
  Badge,
  Image,
  Divider,
  VisuallyHiddenInput,
  Skeleton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast
} from '@chakra-ui/react'
import { AiFillDelete } from 'react-icons/ai';
import { FiPlus, FiImage } from 'react-icons/fi';
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import Datepicker from "react-tailwindcss-datepicker"; 
import dayjs from 'dayjs';
import { useForm, useFieldArray } from "react-hook-form";
import { difficultyOptionsList, featuresOptionsList, NICE_COMMENT_LENGTH, getResizedPhotoUrl, readFile, trackEvent } from 'src/utils';
import { reviewPhotoRef } from 'src/firebase/firebase';
import { useUser, useGlobal } from 'src/context';
import { getOneReview, createReview, updateReview, deleteReview } from "src/api";

// Review section: rating(required), difficulty, features(optional, limit 5), horrorIndex, comment, spoiler, hidden
// Record section: playDate, playTime, variation, passed, note, public, photo 

// rating: 1EP
// difficulty: 5 EP
// comment > NICE_COMMENT_LENGTH: 10 EP

export const initialReviewContent = {
  rating: 0,
  difficulty: '',
  features: [],
  comment: '',
  horrorIndex: 0,
  isSpoiler: false,
  isHidden: false,
}

const initialRecordContent = {
  playDate: {
    startDate: null,
    endDate: null,
  },
  playTime: '',
  variation: '',
  passed: '',
  note: '',
  isPublic: true,
  playPhotoUrl: '',
  remotePlayPhotoUrl: null,
  shouldCheckTime: false
}

const setFormValueOptions = {
  shouldDirty: true, 
  shouldTouch: true,
}

export const organizeReviewContent = reviewData => {
  const { 
    featuresList, 
    records,
  } = reviewData;

  // Legacy:
  // 取回的資料要將 featuresList 拆為 difficulty 與 features 再傳到 UI state
  // 1~3 為 difficulty features
  const difficulty = featuresList.find( feature => feature <= 3 );
  const features = featuresList.reduce((acc, feature) => {
    if (parseInt(feature, 10) > 3) {
      return [...acc, feature];
    }

    return acc;
  }, []);

  const newRecords = records.map((record) => {
    const { playDateTime, ...recordData } = record;
    const newPlayDate = {
      startDate: null,
      endDate: null,
    }

    let playTime = "";
    let shouldCheckTime = false

    // 在改用這個版本的 datepicker 前 timestamp 會是取當下的，所以 HH:mm 可能會有錯，需要提示使用者
    if (playDateTime && playDateTime > 0) {
      const parsePlayDate = dayjs(playDateTime);
      const date = parsePlayDate.format("YYYY-MM-DD")
      const hour = parsePlayDate.get('hour');
      const minute = parsePlayDate.get('minute');
      newPlayDate.startDate = date;
      newPlayDate.endDate = date;

      // timestamp 預設時間為 00:00 ，但為了避免混淆，如果是 00:00 就不顯示；另外此處必須為 hh:mm 格式
      playTime = `${hour === 0 ? "00" : hour < 10 ? "0" + hour : hour}:${minute === 0 ? hour === 0 ? "" : "00" : minute < 10 ? "0" + minute : minute}`
      // timestamp 無法被 1000 ms整除的時間可能都是錯的，或者早於早上九點、晚於晚上十點、分鐘非5的倍數都有可能是錯的
      shouldCheckTime = (playDateTime % 1000 > 0) || ((hour !== 0 && minute !== 0) && (hour < 9 || hour > 22 || minute % 5 > 0))

    }

    return {
      ...recordData,
      playDate: newPlayDate,
      playTime,
      shouldCheckTime,
    }
  });

  return {
    ...reviewData,
    difficulty,
    features,
    records: newRecords,
  };
}

const COMMENT_LENGTH_MAXIMUM = 1000;
const NOTE_LENGTH_MAXUMUM = 100;
const FEATURES_MAXIMUM = 5;

const today = dayjs();

const passedOptionsList = [
  {
    value: 'SUCCESS',
    name: '過關'
  },
  {
    value: 'FAILED',
    name: '失敗'
  },
  {
    value: '',
    name: '其他'
  },
]

const ratingText = ['', '非常糟糕！', '不太好。', '普通。', '很不錯。', '超讚！'];

export const availbleImageFileTypes = ['image/jpeg', 'image/png'];

const ReviewForm = props => {
  const {
    isOpen,
    gameId,
    // title,
    onClose,
    onSubmitSuccess
  } = props;

  const [isPosting, setIsPosting] = useState(false);

  const [title, setTitle] = useState("");
  const [variations, setVariations] = useState([]);
  const [uploadingPhotos, setUploadingPhotos] = useState(0);
  const [confirmType, setConfirmType] = useState(null); // delete, touched,

  const { 
    user: { reviewedGamesIdList }, 
    updateList,
    regetUserInfo
  } = useUser();

  const { isScreenLg } = useGlobal();

  const { 
    control,
    getValues,
    setValue,
    register, 
    watch,
    handleSubmit, 
    reset,
    formState: { 
      isDirty,
      touchedFields,
      errors, 
      isSubmitting 
    }
  } = useForm({
    defaultValues: initialReviewContent
  });

  const { fields, append, update, remove } = useFieldArray({ control, name: "records" });

  const watchFields = watch();
  // console.log(watchFields)

  const toast = useToast();

  const hasReviewed = useMemo(() => {
    if (reviewedGamesIdList.indexOf(gameId) > -1) {
      return true;
    } else {
      append(initialRecordContent); 
      return false;
    }
  }, [gameId, reviewedGamesIdList]);

  const isShowHorrowIndex = useMemo(() => {
    return watchFields.features.indexOf('9') > -1;
  }, [watchFields.features])

  const isUnsubmitable = useMemo(() => {
    return !isDirty || watchFields.rating === 0 || uploadingPhotos > 0
  }, [isDirty, watchFields.rating, uploadingPhotos])

  const { isLoading: isLoadingReview } = useSWR(gameId, getOneReview, {

    onSuccess: (reviewRes) => {
      const { gameTitle, gameVariations, ...remoteData } = reviewRes.data;

      if (!!title) return;

      setTitle(gameTitle);

      if (gameVariations) {
        setVariations(gameVariations);
      }

      if (Object.keys(remoteData).length > 0) {
        populateFormValues(remoteData)
      }
    },
    onError: err => {
      console.debug(err)

      toast({
        title: '發生了一些錯誤',
        description: "請稍後再試",
        status: 'error',
        position: isScreenLg ? 'bottom-right' : 'top',
        duration: 5000,
        isClosable: true,
      })

      onClose();
    }
  });

  useEffect(() => {
    return () => {
      reset(initialReviewContent)
    };
  }, []);

  const populateFormValues = remoteData => {
    if (watchFields.records.length > 0) return;

    const options = {
      shouldValidate: false,
      shouldDirty: false,
      shouldTouch: false
    }

    const {
      rating,
      difficulty,
      features,
      comment,
      horrorIndex,
      isSpoiler,
      isHidden,
      records,
    } = organizeReviewContent(remoteData);

    setValue('rating', rating, options);
    setValue('difficulty', difficulty, options);
    setValue('features', features, options);
    setValue('comment', comment, options);
    setValue('horrorIndex', horrorIndex);
    setValue('isSpoiler', isSpoiler);
    setValue('isHidden', isHidden, options);

    if (records.length) {
      records.forEach((record) => {
        const { playDate, playTime, variation, passed, note, isPublic, playPhotoUrl, shouldCheckTime } = record;
  
        append({
          playDate,
          playTime,
          variation,
          passed,
          note,
          isPublic,
          playPhotoUrl,
          remotePlayPhotoUrl: playPhotoUrl,
          shouldCheckTime
        });
      })
    } else {
      append(initialRecordContent);
    }
  };

  const handleRatingChange = value => {
    setValue('rating', value, setFormValueOptions);
  };

  const handleDifficultyChange = value => {
    setValue('difficulty', value, setFormValueOptions);
  };

  const handleFeatureChange = value => {
    const currentValues = watchFields.features;
    const last = currentValues[currentValues.length -1];
    if (value.indexOf(last) === -1) {
      setValue('features', value, setFormValueOptions);
    } else {
      if (currentValues.length >= FEATURES_MAXIMUM) return;
      setValue('features', value, setFormValueOptions);
    }
  };

  const handleHorrorIndexChange = (valueAsString, valueAsNumber) => {
    if (!valueAsString && !valueAsNumber) {
      setValue('horrorIndex', 0);
    }

    setValue('horrorIndex', valueAsNumber, setFormValueOptions);
  };

  const handleHiddenToggle = () => {
    setValue('isHidden', !watchFields.isHidden, setFormValueOptions);
  };


  const requestSubmitReview = async () => {
    trackEvent({
      category: 'Review_submit',
      action: 'Click',
      label: `${gameId}-${title}`,
      value: 2,
    });

    const {
      rating,
      difficulty,
      features,
      comment,
      horrorIndex,
      isSpoiler,
      isHidden,
      records,
    } = getValues();

    const saveRecords = records.map((record, index) => {
      const { playDate, playTime, variation, passed, note, isPublic, playPhotoUrl, remotePlayPhotoUrl } = record;

      // 若沒有選擇設定遊玩日期，直接設為 0 以供 firestore 做 filter
      let playDateTimestamp = 0;

      if (playDate.startDate) {
        // 為方便歷程排序，需要設定遊玩時間。因欄位分離，若有設定 playTime 合併至 playDate 一起存
        const playDateObj = dayjs(playDate.startDate);

        if (!!playTime) {
          const time = playTime.split(":");

          playDateTimestamp = playDateObj.hour(parseInt(time[0])).minute(parseInt(time[1])).valueOf();
        } else {
          playDateTimestamp = playDateObj.valueOf()
        }
      }

      return {
        index,
        playDateTime: playDateTimestamp,
        variation,
        passed, 
        note, 
        isPublic, 
        playPhotoUrl: (playPhotoUrl && remotePlayPhotoUrl) ? remotePlayPhotoUrl : null
      }
    })

    const payload = {
      gameId,
      rating,
      featuresList: [difficulty, ...features],
      horrorIndex,
      comment,
      isSpoiler,
      isHidden,
      records: saveRecords,
    };

    try {
      if (hasReviewed) {
        // 修改評價， reviewScore 會在 cloud functions 更新
        const res = await updateReview({
          form: payload
        })

        if (res?.data?.isSuccess) {
          toast({
            title: '評價已更新',
            status: 'success',
            position: isScreenLg ? 'bottom-right' : 'top',
            duration: 3000,
            isClosable: true,
          })

          onSubmitSuccess?.('EDIT', gameId, payload);

          onClose();
        }
      } else {
        const res = await createReview({
          form: payload
        });

        const { 
          isSuccess,
          hasNewAchivement,
          hasNewChallenge
        } = res.data;

        if (isSuccess) {
          updateList('review', gameId);

          toast({
            title: '評價已送出',
            description: "感謝您的貢獻",
            status: 'success',
            position: isScreenLg ? 'bottom-right' : 'top',
            duration: 3000,
            isClosable: true,
          })
        }

        if (hasNewAchivement || hasNewChallenge) {
          regetUserInfo();
        }

        if (hasNewAchivement) {
          toast({
            title: '你已解鎖新成就',
            description: "請至會員中心查看",
            status: 'info',
            position: isScreenLg ? 'bottom-right' : 'top',
            duration: 6000,
            isClosable: true,
          })
        }

        if (hasNewChallenge) {
          toast({
            title: '你已解鎖新的鐵粉挑戰',
            description: "請至工作室頁面查看",
            status: 'info',
            position: isScreenLg ? 'bottom-right' : 'top',
            duration: 6000,
            isClosable: true,
          })
        }

        onClose();
      }
    } catch (err) {
      console.debug(err);

      toast({
        title: '發生了一些錯誤',
        description: "請稍後再試",
        status: 'error',
        position: isScreenLg ? 'bottom-right' : 'top',
        duration: 5000,
        isClosable: true,
      })
    }
  };

  const handleAppendRecord = () => {
    append(initialRecordContent);
  };

  const handleRemoveRecord = (index) => {
    remove(index)
  };

  const handleClose = () => {
    if (Object.values(touchedFields).length) {
      setConfirmType('touched');
    } else {
      onClose();
    }
  };

  const handleDeleteClick = () => {
    setConfirmType('delete');
  }

  const handleConfirmLeave = () => {
    setConfirmType(null);
    onClose();
  }

  const handleCancelLeave = () => {
    setConfirmType(null);
  }

  const requestDeleteReview = async () => {
    trackEvent({
      category: 'Review_delete',
      action: 'Click',
      label: `${gameId}-${title}`,
    });

    setIsPosting(true);

    try {
      await deleteReview({ gameId })

      toast({
        title: '刪除成功',
        status: 'success',
        position: isScreenLg ? 'bottom-right' : 'top',
        duration: 3000,
        isClosable: true,
      });

      onSubmitSuccess?.('DELETE', gameId);

      onClose();
    } catch (err) {
      console.debug(err);

      toast({
        title: '發生了一些錯誤',
        description: "請稍後再試",
        status: 'error',
        position: isScreenLg ? 'bottom-right' : 'top',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsPosting(false);
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      placement={isScreenLg ? 'right' : 'bottom'}
      size='md'
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader px={3}>
          <Skeleton isLoaded={!isLoadingReview}>
            {title}
          </Skeleton>
        </DrawerHeader>

        {isLoadingReview ? (
          <SekeletonGroup />
        ) : (
          <DrawerBody px={3}>
            <Stack spacing={4}>
              <FormControl isInvalid={errors.rating}>
                <FormLabel 
                  htmlFor='rating'
                  pl={2}
                  borderLeftWidth='4px'
                  borderLeftColor='primary.500'
                  lineHeight='1'
                >
                  整體評價
                </FormLabel>
                <HStack>
                  <Rating 
                    style={{ maxWidth: 120 }}
                    value={watchFields.rating}
                    onChange={handleRatingChange}
                  />
                  <Text fontSize='sm'>{ratingText[watchFields.rating]}</Text>
                </HStack>
                <FormHelperText>依據您遊玩的經驗，整體而言你會給這個遊戲幾分？</FormHelperText>
                  {errors.rating && <FormErrorMessage>請給分</FormErrorMessage>}
              </FormControl>
              
              <FormControl isInvalid={errors.difficulty}>
                <FormLabel 
                  htmlFor='difficulty'
                  pl={2}
                  borderLeftWidth='4px'
                  borderLeftColor='primary.500'
                  lineHeight='1'
                >
                  難度
                </FormLabel>
                <RadioGroup 
                  value={watchFields.difficulty}
                  onChange={handleDifficultyChange}
                  colorScheme='secondary'
                >
                  <HStack spacing={3}>
                    {difficultyOptionsList.map((optionObj) => {
                      const { value, name } = optionObj;
                      return (
                        <Radio key={value} value={value}>
                          {name}
                        </Radio>
                      )
                    })}
                  </HStack>
                </RadioGroup>
              </FormControl>

              <FormControl isInvalid={errors.features}>
                <FormLabel 
                  htmlFor='features'
                  pl={2}
                  borderLeftWidth='4px'
                  borderLeftColor='primary.500'
                  lineHeight='1'
                >
                  特色與氣氛
                </FormLabel>
                <CheckboxGroup
                  colorScheme='secondary'
                  onChange={handleFeatureChange}
                  value={watchFields.features}
                >
                  <HStack spacing={3} flexWrap='wrap'>
                    {featuresOptionsList.map((optionObj) => {
                      const { value, name } = optionObj;
                      const selected = watchFields.features.includes(value);
                      return (
                        <Checkbox 
                          key={value}
                          value={value}
                          isDisabled={(watchFields.features.length >= FEATURES_MAXIMUM) && !selected}
                        >
                          {name}
                        </Checkbox>
                      )
                    })}
                  </HStack>
                </CheckboxGroup>
                <FormHelperText align='right'>可選最多 {FEATURES_MAXIMUM} 項</FormHelperText>
              </FormControl>

              {isShowHorrowIndex && (
                <FormControl isInvalid={errors.horrorIndex}>
                  <FormLabel 
                    htmlFor='horrorIndex'
                    pl={2}
                    borderLeftWidth='4px'
                    borderLeftColor='primary.500'
                    lineHeight='1'
                  >
                    恐怖指數
                    <Badge ml={2} variant='subtle' colorScheme='green'>BETA</Badge>
                  </FormLabel>
                    <NumberInput 
                      size='sm' 
                      maxW={20} 
                      min={0} 
                      max={10}
                      inputMode='numeric'
                      value={watchFields.horrorIndex}
                      onChange={handleHorrorIndexChange}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  <FormHelperText>你認為這款遊戲有多恐怖？10 為最恐怖</FormHelperText>
                </FormControl>
              )}

              <FormControl isInvalid={errors.comment}>
                <FormLabel 
                  htmlFor='comment'
                  pl={2}
                  borderLeftWidth='4px'
                  borderLeftColor='primary.500'
                  lineHeight='1'
                >
                  體驗心得
                </FormLabel>
                <Textarea
                  placeholder='盡情分享你的體驗心得'
                  {...register('comment', { maxLength: COMMENT_LENGTH_MAXIMUM })} 
                />
                <FormHelperText align='right'>已輸入 {watchFields.comment.length} / {COMMENT_LENGTH_MAXIMUM} 字</FormHelperText>
                {errors.comment && <FormErrorMessage>字數超過上限</FormErrorMessage>}
              </FormControl>

              <FormControl isInvalid={errors.isSpoiler}>
                <FormLabel 
                  htmlFor='isSpoiler'
                  pl={2}
                  borderLeftWidth='4px'
                  borderLeftColor='primary.500'
                  lineHeight='1'
                >
                  以上心得是否有劇透（暴雷）？
                </FormLabel>
                <HStack spacing={2}>
                  <Switch 
                    colorScheme='secondary'
                    id='isSpoiler'
                    isChecked={watchFields.isSpoiler}
                    {...register('isSpoiler')}
                  />
                  <Text>{watchFields.isSpoiler ? '有劇透' : '無劇透'}</Text>
                </HStack>
                <FormHelperText>打造友善分享環境，若你分享的心得適合玩過再看，建議開啟此項。</FormHelperText>
              </FormControl>

              <FormControl isInvalid={errors.isHidden}>
                <FormLabel 
                  colorScheme='secondary'
                  htmlFor='isHidden'
                  pl={2}
                  borderLeftWidth='4px'
                  borderLeftColor='primary.500'
                  lineHeight='1'
                >
                  公開分享心得？
                </FormLabel>
                <HStack>
                  <Switch 
                    colorScheme='secondary'
                    id='isHidden'
                    isChecked={!watchFields.isHidden}
                    onChange={handleHiddenToggle}
                  />
                  <Text>{watchFields.isHidden ? '不公開' : '公開'}</Text>
                </HStack>
                <FormHelperText>若選擇不公開，你的心得不會顯示在「玩家評價」區分享給其他玩家參考。</FormHelperText>
              </FormControl>

              <Divider />

              {fields.map((field, index) => (
                <RecordForm 
                  key={field.id} 
                  index={index}
                  control={control}
                  field={field}
                  update={update}
                  onDelete={handleRemoveRecord}
                  onUploadBegin={() => setUploadingPhotos(prev => prev + 1)}
                  onUploadFinish={() => setUploadingPhotos(prev => prev - 1)}
                  variations={variations}
                />
              ))}
    
              <Button 
                onClick={handleAppendRecord}
                variant='outline'
                leftIcon={<FiPlus />}
                colorScheme='primary'
              >
                再玩一次
                <Badge ml={2} variant='subtle' colorScheme='green'>NEW</Badge>
              </Button>

            </Stack>
          </DrawerBody>
        )}

        {!isLoadingReview && (
          <DrawerFooter 
            justifyContent="space-between"
            borderTop='1px' 
            borderTopColor='gray.200'
            >
            {hasReviewed && (
              <IconButton
                variant='outline'
                fontSize='1.5rem'
                colorScheme='red'
                icon={<AiFillDelete />}
                isDisabled={isPosting || uploadingPhotos > 0}
                onClick={handleDeleteClick}
                />
            )}

            <HStack flexGrow='1' justify='flex-end'>
              <Button 
                variant='outline' 
                mr={3} 
                isDisabled={isPosting || uploadingPhotos > 0}
                onClick={handleClose}
                >
                取消
              </Button>
              <Button 
                colorScheme='blue'
                isLoading={isSubmitting || isPosting}
                isDisabled={isUnsubmitable}
                onClick={handleSubmit(requestSubmitReview)}
                >
                送出
              </Button>
            </HStack>
          </DrawerFooter>
        )}
      </DrawerContent>
      <ConfirmDialog
        confirmType={confirmType}
        onClose={handleCancelLeave}
        onLeave={handleConfirmLeave}
        onDelete={requestDeleteReview}
      />
    </Drawer>
  )
}

const RecordForm = ({ control, index, field, update, onDelete, onUploadBegin, onUploadFinish, variations }) => {
  const [isFileTypeUnavailable, setIsFileTypeUnavailable] = useState(false);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);

  const { 
    user: { customId }, 
  } = useUser();

  const { register, watch, setValue, getValues, errors } = useForm({
    defaultValues: field
  });
  const data = watch();

  const handleDateChange = valueSet => {
    setValue('playDate', valueSet, setFormValueOptions);
    update(index, getValues());
  };

  const handlePassedChange = value => {
    setValue('passed', value, setFormValueOptions);
    update(index, getValues());
  };

  const handleFileSelect = async e => {
    setIsFileTypeUnavailable(false);

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (availbleImageFileTypes.indexOf(file.type) === -1) {
        setIsFileTypeUnavailable(true);
        return;
      }

      let imageDataUrl = await readFile(file);
      // 先放入本地檔案顯示於圖檔區
      setValue('playPhotoUrl', imageDataUrl, setFormValueOptions)
      update(index, getValues());
      uploadPhoto(file)
    }
  };

  const uploadPhoto = async (file) => {
    const { type, lastModified, size } = file;
    let fileType = '';
  
    switch (type) {
      case 'image/png':
        fileType = '.png';
        break;
      case 'image/jpeg':
      default:
        fileType = '.jpg';
        break;
    };

    const timestamp = new Date().getTime();
    
    const uploadRef = reviewPhotoRef.child(`${customId}_${timestamp}${fileType}`);
  
    onUploadBegin()
    setIsUploadingPhoto(true);

    const uploadTask = uploadRef.put(file);

    uploadTask.on('state_changed', (snapshot) => {
    }, (error) => {
      console.table(error);
      // Sentry.captureException(error);
      setIsUploadingPhoto(false);
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        // 因一開始上傳完的 url 會在轉檔後沒多久被 cloud storage 自動移除，但也無法確定何時會產生有 _600x600 的 suffix 的檔案，因此用本地 base64 顯示，但把實際雲端 url 暫存
        setValue('remotePlayPhotoUrl', getResizedPhotoUrl(downloadURL))
        update(index, getValues());
        setIsUploadingPhoto(false);
        onUploadFinish()
      });
    });
  };

  const handleDeletePlayPhotoUrl = () => {
    // TODO: 背景執行的 cloud function 比對 playPhotoUrl 不同則刪除舊的圖
    setValue('playPhotoUrl', '', setFormValueOptions);
    setValue('remotePlayPhotoUrl', null);
    update(index, getValues());
  };

  const handleDeleteRecord = () => {
    onDelete(index);
  }

  return (
    <Stack 
      p={4}
      spacing={4} 
      border='1px' 
      borderColor='primary.200' 
      borderRadius="md"
    >
      <HStack justify='space-between'>
        <p className='text-lg font-semibold'>
          {index > 0 ? `第 ${index + 1} ` : `首`}次遊玩紀錄
        </p>

        {index > 0 && (
          <IconButton
            variant='ghost'
            colorScheme='red'
            icon={<AiFillDelete />}
            onClick={handleDeleteRecord}
          />
        )}
      </HStack>
      <FormControl>
        <FormLabel 
          htmlFor='playDate'
          pl={2}
          borderLeftWidth='4px'
          borderLeftColor='primary.500'
          lineHeight='1'
        >
          遊玩日期與時間
        </FormLabel>
        <Datepicker 
          asSingle={true}
          useRange={false} 
          i18n="zh-tw"
          placeholder="請選擇日期"
          startWeekOn="mon" 
          maxDate={today}
          name='playDate' 
          value={data.playDate} 
          onChange={handleDateChange} 
        /> 
        <HStack spacing={2}>
          <Text fontSize='sm' w='6rem'>遊玩時間</Text>
          <Input
            placeholder="請先選日期再設定時間"
            size="sm"
            isDisabled={!data.playDate.startDate}
            type="time"
            {...register('playTime', {
              onBlur: e => {
                setValue('shouldCheckTime', false);
                update(index, getValues())
              }
            })}
          />
        </HStack>
        {data.shouldCheckTime && <FormHelperText color='red'>此遊玩時間可能有誤，請確認。</FormHelperText>}
        <FormHelperText>遊玩時間的用途為在「歷程」時間軸上正確顯示遊玩順序。</FormHelperText>
      </FormControl>

      {variations.length > 0 && (
        <FormControl>
          <FormLabel 
            htmlFor='variation'
            pl={2}
            borderLeftWidth='4px'
            borderLeftColor='primary.500'
            lineHeight='1'
          >
            遊玩路線／角色／情境
          </FormLabel>
          <Select
            placeholder='請選擇'
            {...register('variation', {
              onBlur: e => {
                update(index, getValues())
              }
            })}
          >
            {variations.map(v => <option key={v} value={v}>{v}</option>)}
          </Select>
        </FormControl>
      )}

      <FormControl isInvalid={errors?.passed}>
        <FormLabel 
          htmlFor='passed'
          pl={2}
          borderLeftWidth='4px'
          borderLeftColor='primary.500'
          lineHeight='1'
        >
          遊戲目的達成狀況
        </FormLabel>
        <RadioGroup
          value={data.passed}
          colorScheme='secondary'
          onChange={handlePassedChange}
          onBlur={() => update(index, getValues())}
        >
          <HStack spacing={6}>
            {passedOptionsList.map((optionObj) => {
              const { value, name } = optionObj;
              return (
                <Radio key={value} value={value}>
                  {name}
                </Radio>
              )
            })}
          </HStack>
        </RadioGroup>
      </FormControl>

      <FormControl isInvalid={errors?.note}>
        <FormLabel 
          htmlFor='note'
          pl={2}
          borderLeftWidth='4px'
          borderLeftColor='primary.500'
          lineHeight='1'
        >
          遊戲完成程度簡短紀錄
        </FormLabel>
        <Input 
          placeholder='例如：48分完美過關、最終選擇A路線'
          {...register('note', { 
            maxLength: NOTE_LENGTH_MAXUMUM,
            onBlur: e => update(index, getValues())
          })}
        />
        <FormHelperText>可在此填寫本次遊戲的過關時間，或者進到何種結局。限 {NOTE_LENGTH_MAXUMUM} 字內。</FormHelperText>
        {errors?.note && <FormErrorMessage>字數超過上限</FormErrorMessage>}
      </FormControl>

      <FormControl isInvalid={errors?.isPublic}>
        <FormLabel 
          htmlFor='isPublic'
          pl={2}
          borderLeftWidth='4px'
          borderLeftColor='primary.500'
          lineHeight='1'
        >
          紀錄在你的「歷程」中？
        </FormLabel>
        <HStack spacing={2}>
          <Switch 
            colorScheme='secondary'
            isChecked={data.isPublic}
            {...register('isPublic', {
              onBlur: () => update(index, getValues())
            })}
          />
          <Text>{data.isPublic ? '紀錄' : '不紀錄'}</Text>
        </HStack>
        <FormHelperText>若開啟此項且有紀錄遊玩日期，會顯示在會員中心「歷程」時間軸。</FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel 
          htmlFor='playPhotoUrl'
          pl={2}
          borderLeftWidth='4px'
          borderLeftColor='primary.500'
          lineHeight='1'
        >
          遊玩照片
        </FormLabel>
        <HStack spacing={2}>
          {data.playPhotoUrl && (
            <Skeleton isLoaded={!isUploadingPhoto}>
              <Image
                boxSize='300px'
                objectFit='contain'
                src={data.playPhotoUrl}
              />
            </Skeleton>
          )}

          {data.playPhotoUrl ? (
            <IconButton
              isRound={true}
              variant='outline'
              fontSize='1.5rem'
              icon={<AiFillDelete />}
              onClick={handleDeletePlayPhotoUrl}
            />
          ) : (
            <>
              <IconButton
                as='label'
                htmlFor={`play-photo-${index}`}
                isRound={true}
                variant='outline'
                fontSize='1.5rem'
                icon={<FiImage />}
                _hover={{ cursor: 'pointer' }}
              />
              <VisuallyHiddenInput
                id={`play-photo-${index}`}
                name={`play-photo-${index}`}
                type="file"
                accept="image/png, image/jpeg"
                className=''
                onChange={handleFileSelect}
              />
            </>
          )}
        </HStack>
        <FormHelperText>遊玩照片會顯示在會員中心「歷程」時間軸中。</FormHelperText>
        {isFileTypeUnavailable && <FormErrorMessage>僅能上傳 JPG/PNG 檔案</FormErrorMessage>}
      </FormControl>
    </Stack>
  )
};

const ConfirmDialog = ({ confirmType, onClose, onLeave, onDelete }) => {
  const cancelRef = useRef()

  const isDelete = confirmType === "delete";
  const onConfim = isDelete ? onDelete : onLeave;

  return (
    <AlertDialog
      isOpen={!!confirmType}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {isDelete ? "確認要刪除此則評價嗎？" : "確定要離開嗎？"}
          </AlertDialogHeader>

          <AlertDialogBody>
            {isDelete ? "被刪除的內容（包含評價與紀錄）無法還原，而且會扣除已經獲得的 EP 喔！你可以選擇不公開分享來隱藏自己玩過哪些遊戲。" : "好像有些內容尚未儲存喔！"}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {isDelete ? "放棄刪除" : "繼續編輯"}
            </Button>
            <Button colorScheme='red' onClick={onConfim} ml={3}>
              {isDelete ? "確定刪除" : "確定離開"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
};

const SekeletonGroup = () => {
  return (
    <Stack p={4} spacing={4}>
      <Skeleton height='24px' />
      <Skeleton height='24px' />
      <Skeleton height='24px' />
      <Skeleton height='24px' />
      <Skeleton height='160px' />
      <Skeleton height='24px' />
      <Skeleton height='160px' />
    </Stack>
  )
};

export default ReviewForm;